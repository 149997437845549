import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import GradientScroll from "react-gradient-scroll-indicator";

import { useStyles } from "./styles";

const PopePrayerView = ({ pray }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const scrollRef = useRef(null);
  const titleRef = useRef(null);

  return (
    <div data-testid={`home_view`} className={classes.popePrayerContent}>
      <section className={classes.popePrayerContainer}>
        <div className={classes.popePrayerHeader}>
          <p className={classes.popePrayerHeaderTitle}>{t("pages.popeProfile.Pope Francis' official prayer profile")}</p>
        </div>
        <div className={classes.popePrayerBody}>
          <div  className={classes.popePrayerPopeSection}>
            <div>
              <img src="/static/media/Pope_photo_large.324e0900.svg" className={classes.popePrayerAvatar} />
            </div>
            <div>
              <p className={classes.popePrayerPopeTitle}>{t("components.header.Pope Francis")}</p>
              <p className={classes.popePrayerPopeDescription}>{pray.title}</p>
            </div>
          </div>
          <div className={classes.popePrayerContentContainer}>
            <GradientScroll
              ref={scrollRef}
              fadeColor="rgba(255,255,255,0)"
              primaryColor="#ffffff"
              fadeHeight="20px"
            >
              <div ref={titleRef}>
                <p className={classes.popePrayerContentText}>{pray.description}</p>
              </div>
            </GradientScroll>
          </div>
          <div className={classes.popePrayerFooterContainer}>
            <a href="https://clicktopray.org/pope" target="_blank" rel="noreferrer" className={classes.popePrayerFooter}>
              <div className={classes.popePrayerCPTContainer}>
                <img className={classes.popePrayerCTPIcon} alt="Clic to Pray" src="/static/media/Logo_simple.3ac4977e.svg" />
                <div>
                  <p className={classes.popePrayerCPTTitle}>{t("pages.popeProfile.Pray in CTP")}</p>
                </div>
              </div>
              <img className={classes.popePrayerCPTArrow} src="/static/media/Arrow Right.decc3cb7.svg" alt=" " />
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

PopePrayerView.propTypes = {};

export default PopePrayerView;
